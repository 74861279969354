import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../component/count.css' 

const Statistics = () => {
  const stats = [
    { label: 'Years of Excellence', value: '10+' },
    { label: 'Happy Families', value: '1000+' },
    { label: 'Projects Completed', value: '10+' },
    { label: 'Ongoing Projects', value: '2+' },
    { label: 'Up Coming Projects', value: '2+' },
  ];

  return (
    <Container fluid className="p-5 icon-page" id='count'>
      <Row className="text-center justify-content-center">
        {stats.map((stat, index) => (
          <Col key={index} xs={4} sm={4} md={4} lg={2} className="mb-5 ">
            <div className="stat-box p-3">
              <div className="textcol"><strong>{stat.value}</strong></div>
              <div className='textrow'>{stat.label}</div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Statistics;
