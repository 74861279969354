import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../component/plan.css'; // Import the CSS file
import Image from '../assets/images/plan1.png'; // Adjust the import paths
import Image1 from '../assets/images/plan2.png';
import Image2 from '../assets/images/plan3.png';
import boader from '../assets/images/boader.png'

const Masterplan = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const handleNavClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
    <div className='master-plan' id='plan'>

    <h2 className="sticky">Design Visualization<br></br>
    <img src={boader} alt='boader' className='boader'/></h2>
      <section className="service-nav-section">
        <div className="container service ">
          {/* <div className='row'> */}
          {/* <div className='getin text-center'> */}
          {/* <h2 className="sticky">Master plan<br></br>
          <img src={boader} alt='boader' className='boader'/></h2> */}
          {/* </div> */}
          <div className="service-nav">
            <ul className="service-nav-list">
              <li className={`service-nav-item ${activeIndex === 0 ? 'active' : ''}`}>
                <button onClick={() => handleNavClick(0)} className="service-nav-link">
                  Master Plan
                </button>
              </li>
              <li className={`service-nav-item ${activeIndex === 1 ? 'active' : ''}`}>
                <button onClick={() => handleNavClick(1)} className="service-nav-link">
                  Typical Floor Plans
                </button>
              </li>
              <li className={`service-nav-item ${activeIndex === 2 ? 'active' : ''}`}>
                <button onClick={() => handleNavClick(2)} className="service-nav-link">
                  Isometric View
                </button>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="hero" className="hero section">
        <div className="container-fluid">
          <Carousel activeIndex={activeIndex} onSelect={handleSelect} interval={5000} style={{zIndex:"0"}}>
            <Carousel.Item>
              <div className="flex-row-container">
                <div className="col-lg-12 d-flex justify-content-center align-items-center ">
                  <img src={Image} className="hero-imgs" alt="hero-img" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item  style={{zIndex:"0"}}>
              <div className="flex-row-container">
                <div className="col-lg-12 d-flex justify-content-center align-items-center ">
                  <img src={Image1} className=" hero-img" alt="hero-img" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item  style={{zIndex:"0"}}>
              <div className="flex-row-container">
                <div className="col-lg-12 d-flex justify-content-center align-items-end ">
                  <img src={Image2} className=" hero-img" alt="hero-img" />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
    </div>
    </>
  );
};

export default Masterplan;


