import React from 'react';
import '../component/amenities';
import swiming from '../assets/images/swiming.png';
import location from '../assets/images/loc.png';
import cyc from '../assets/images/cyc.png';
import petrol from '../assets/images/petrol.png';
import gym from '../assets/images/gym.png';
import bb from '../assets/images/bb.png';
import snoor from '../assets/images/snoor.png';
import tennis from '../assets/images/tennis.png';
import outs from '../assets/images/outs.png';
import yoga from '../assets/images/yoga.png';
import sky from '../assets/images/sky.png';
import dance from '../assets/images/dance.png';
import food from '../assets/images/food.png';
import jogging from '../assets/images/jogging.png';
import ev from '../assets/images/ev.png';
import boader from '../assets/images/boader.png'

const amenities = [
    { img: swiming, alt: 'Swimming Pool', label: 'Swimming Pool' },
    { img: location, alt: 'Terrace Garden', label: 'Terrace Garden' },
    { img: cyc, alt: 'Piped Gas', label: 'Piped Gas' },
    { img: petrol, alt: 'Banquet Hall', label: 'Banquet Hall' },
    { img: gym, alt: 'Gymnasium', label: 'Gymnasium' },
    { img: bb, alt: 'Amphitheatre', label: 'Amphitheatre' },
    { img: snoor, alt: 'Snooker', label: 'Snooker' },
    { img: tennis, alt: 'Table Tennis', label: 'Table Tennis' },
    { img: outs, alt: 'Outdoor Screen', label: 'Outdoor Screen' },
    { img: tennis, alt: 'Indoor Games', label: 'Indoor Games' },
    { img: petrol, alt: 'Vastu Compliant', label: 'Vastu Compliant' },
    { img: petrol, alt: 'Multi-purpose Hall', label: 'Multi-purpose Hall' },
    { img: yoga, alt: 'Yoga/Meditation', label: 'Yoga/Meditation' },
    { img: sky, alt: 'Sky Banqueting', label: 'Sky Banqueting' },
    { img: dance, alt: 'Dance Studio', label: 'Dance Studio' },
    { img: food, alt: 'Roof Top Barbeque', label: 'Roof Top Barbeque' },
    { img: location, alt: 'Roof Top Gazebos', label: 'Roof Top Gazebos' },
    { img: jogging, alt: 'Jogging Track', label: 'Jogging Track' },
    { img: ev, alt: 'EV Charging Point', label: 'EV Charging Point' },
    { img: outs, alt: 'Senior Citizen Kiosk', label: 'Senior Citizen Kiosk' },
];

const Amenities = () => {
  return (
   
   
    <div className='container-fluid icon-page ' id='Amenities'>
    <div className="project-amenities ">
      <h2 className='sticky'>Amenities<br></br>
      <img src={boader} alt='boader'className='boader'/></h2>
      <div className="row justify-content-center">
        {amenities.map((amenity, index) => (
          <div className="amenity col-lg-4 col-md-4 col-sm-6 col-xs-6" key={index}>
            <img src={amenity.img} alt={amenity.alt} />
            <p>{amenity.label}</p>
          </div>
        ))}
      </div>
    </div>
    </div>

  
  );
};

export default Amenities;
