import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import img from '../assets/images/gallary.png';
import img1 from '../assets/images/gallary1.png';
import img2 from '../assets/images/gallery2.png';
import img3 from '../assets/images/gallery3.png';
import boader from '../assets/images/boader.png';
import '../component/gallary.css';


function Gallery() {
  // State to keep track of the main image
  const [mainImage, setMainImage] = useState(img);

  // Function to change the main image when a thumbnail is clicked
  const handleImageClick = (newImage) => {
    setMainImage(newImage);
  };

  return (
    <div className="gallery-container" id='gallery'>
      <div className="container ">
        <div className='row'>
        {/* <div className=" getin text-center "> */}
        <h2 className='sticky'>Gallery<br></br>
        <img src={boader} alt='boader' className='boader'/></h2>
        {/* </div> */} 
        <div className="row">
          <div className="col-12">
            <div className="main-image">
              <img src={mainImage} alt="Main Gallery" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-2.4">
            <img
              src={img1}
              alt="Gallery 1"
              className="img-fluid"
              onClick={() => handleImageClick(img1)}
            />
          </div>
          <div className="col-12 col-md-2.4">
            <img
              src={img2}
              alt="Gallery 2"
              className="img-fluid"
              onClick={() => handleImageClick(img2)}
            />
          </div>
          <div className="col-12 col-md-2.4">
            <img
              src={img3}
              alt="Gallery 3"
              className="img-fluid"
              onClick={() => handleImageClick(img3)}
            />
          </div>
          <div className="col-12 col-md-2.4">
            <img
              src={img1}
              alt="Gallery 4"
              className="img-fluid"
              onClick={() => handleImageClick(img1)}
            />
          </div>
          <div className="col-12 col-md-2.4">
            <img
              src={img2}
              alt="Gallery 5"
              className="img-fluid"
              onClick={() => handleImageClick(img2)}
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;


