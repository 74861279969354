import React from 'react';
import img from '../assets/images/projectg.png';
import '../component/project.css'
import '../assets/css/main.css'
import boader from'../assets/images/boader.png'

const ProjectDetails = () => {
  return (
    <div className="project-container " id="projectdetail">
      <div className="project-header row">
        {/* <div className='getin text-center'>  */}
           <h2 className='sticky'>Project Highlights<br></br>
           <img src={boader} alt='boader' className='boader'/></h2>
        {/* </div> */}
        <div className="project-header">
        <div className=" col-md-6 d-flex justify-content-end align-items-end">
       
        
          <img src={img} alt="project" />
          
        </div>
        <div className="project-details col-md-6 text-start">
        
        <ul>
            <li>G+9 with 106 Premium Residence</li>
            <li>Exclusive views in all directions</li>
            <li>Located right on 200’ wide NH-765 (Srisailam Highway)</li>
            <li>Minimum 10’ wide gaps between each flat</li>
            <li>Sewerage Treatment Plant</li>
            <li>Rainwater Harvesting</li>
            <li>Intercom Connectivity</li>
            <li>24X7 Security and Power Backup</li>
            <li>Fire and Safety Management System</li>
            <li>Centralized Piped Gas</li>
            <li>Multiple Lifts for Residents Services</li>
            <li>Ample day Light & Ventilation</li>
            <li>High-end Premium Specification</li>
            <li>Vehicle Movement Free Podium</li>
            <li>100% Vaasthu Compliant</li>
          </ul>
      </div>
      </div>
      </div>
      </div>
  );
};

export default ProjectDetails;
