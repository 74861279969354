import React, { useRef, useState } from 'react';
import '../component/video.css'; // Import custom CSS 
import pauseImg from '../assets/images/pause.png'; // Import the pause image if needed
import playImg from '../assets/images/play.png'; // Import the play image if needed
import boader from '../assets/images/boader.png';

const Video = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const videoURL = 'https://siimatechnologies.com/aryavartalifespacesVideo/MainVideo.mov';

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setShowButton(true); // Show button again when paused
      } else {
        videoRef.current.play();
        setShowButton(false); // Hide button after playing
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="containers video" id='Living'>
      <div className="row justify-content-center">
        <h2 className="sticky">Living the good life<br></br>
          <img src={boader} alt='boader' className='boader' />
        </h2>
        <div className="col-md-0.9 d-flex justify-content-center"></div>
        <div className="col-md-10 d-flex justify-content-center">
          <div className="video-containers justify-content-center">
            <video
              ref={videoRef}
              className="video-fluids"
              loop
              onClick={togglePlayPause} // Add this line to make the video clickable
            >
              <source src={videoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {showButton && (
              <div className="play-buttons" onClick={togglePlayPause}>
                <img
                  src={isPlaying ? pauseImg : playImg}
                  alt={isPlaying ? "Pause" : "Play"}
                  className="pause-button"
                />
              </div>
            )}
            <div className="col-md-1 d-flex justify-content-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
