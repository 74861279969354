import React from 'react';
import { Link } from 'react-router-dom';
import '../component/ongoing.css';
import img from '../assets/images/ongoing.png';
import boader from '../assets/images/boader.png';
import '../assets/css/main.css';

const OngoingProjects = () => {
  return (
    <div className="ongoing-projects" id='projects'>
      <div className="project-header row">
        {/* <div className='getin text-center'>   */}
          <h2 className="sticky">Ongoing Projects<br></br>
          <img src={boader} alt='boader' className='boader'/></h2>
        {/* </div> */}
        <div className="col-md-6 d-flex justify-content-end align-items-end ">
          <img src={img} alt="Aryavarta One" style={{borderRadius:"20px"}} />
        </div>
        <div className="project-details col-md-6 text-start">
          <h3 style={{fontSize:"35px"}}><strong>Aryavarta One</strong></h3>
          <p>
            ARYAVARTA ONE is an ultimate statement of luxurious living perfectly laid in unison with the serenity of nature. 
            Built on a 1.25-acre space sporting 106, 3-BHK opulent flats, ARYAVARTA ONE is located on the Thukkuguda road 
            that is surrounded by greenery, tranquility, and peace. Maximum greener spaces, world-class landscaping, ample 
            intervention of sunlight, and cleaner air enable a healthy living that soothes your body and mind. Embedded with 
            world-class amenities, great connectivity, and comforts to pamper you to the fullest, ARYAVARTA ONE makes sure 
            you live your life king-size, experiencing resplendence, sustainability, and innovation.
          </p>
          
          <Link to="/Aryavata_one">
            <button style={{borderRadius:"40px",color:"#ECBF5D"}}>View More</button>
          </Link>
          
        </div>
      </div>
    </div>
  );
};

export default OngoingProjects;
