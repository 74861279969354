import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom'; 
import '../component/about.css';
import img from'../assets/images/about.png';
import icon from '../assets/images/build.png';
import icon1 from '../assets/images/location.png';
import icon2 from '../assets/images/community.png';
import icon3 from '../assets/images/24.png';
import icon4 from '../assets/images/b.png';
import boader from '../assets/images/boader.png';

const About = () => {
  const location = useLocation(); 

  // Map of paths to headings
  const headings = {
    "/": { heading: 'About Us' },
    "/aryavathaone": { heading: 'About Project' },
    // Add more paths and corresponding headings as needed
  };

  // Get the current heading based on the pathname, with a default fallback
  const currentHeading = headings[location.pathname] || { heading: 'About Project' };

  
  return ( 

    <div className="about-container" id='about'> 
      <div className="project-header row">
      {/* <div className='getin text-center'> */}
          <h2 className="sticky">{currentHeading.heading}<br></br>
          <img src={boader} alt='boader' className='boader'/>
          </h2>
        {/* </div> */}
       <div className="project-header ">
        <div className="col-md-6 d-flex justify-content-end align-items-end">
          <img src={img} alt="Aryavarta One" />
        </div>
        <div className="project-details col-md-6 text-start">
          <p>ARYAVARTA ONE is an ultimate statement of luxurious living perfectly laid in unison with the serenity of nature. Built on a 1.25-acre space sporting 106, 3-BHK opulent flats, ARYAVARTA ONE is located on the Thukkuguda road that is surrounded by greenery, tranquility, and peace. Maximum greener spaces, world-class landscaping, ample intervention of sunlight, and cleaner air enable a healthy living that soothes your body and mind. Embedded with world-class amenities, great connectivity, and comforts to pamper you to the fullest, ARYAVARTA ONE makes sure you live your life king-size experiencing resplendence, sustainability, and innovation.</p>
        </div>
      </div>
      <div className="row pro-info">
        {/* <div className="col-lg-0.5"></div> */}
        <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4">
          </div>
        <div className="project-card text-center col-lg-2 col-md-2 col-sm-4 col-xs-4">
          <img src={icon} className='mb-1' alt="106 Elite 3-BHK Flats"/>
          <h6>106 ELITE<br/>3-BHK FLATS</h6>
        </div>
        <div className="project-card text-center col-lg-2 col-md-2 col-sm-4 col-xs-4">
          <img src={icon1} className='mb-1' alt="1.25 Acres"/>
          <h6>1.25 ACRES</h6>
        </div>
        <div className="project-card text-center col-lg-2 col-md-2 col-sm-4 col-xs-4">
          <img src={icon2} className='mb-1' alt="Luxurious Amenities"/>
          <h6>LUXURIOUS<br/>AMENITIES</h6>
        </div>
        <div className="project-card text-center col-lg-2 col-md-2 col-sm-4 col-xs-4">
          <img src={icon4} className='mb-1' alt="Luxurious Amenities"/>
          <h6>LUXURIOUS<br/>AMENITIES</h6>
        </div>
        <div className="project-card text-center col-lg-2 col-md-2 col-sm-4 col-xs-4">
          <img src={icon3} className='mb-1' alt="24/7 Security"/>
          <h6>24/7<br/>SECURITY</h6>
        </div>
        <div className=" text-center col-lg-1 col-md-1 col-sm-4 col-xs-4">
        </div>
      </div>
    </div>
    </div> 
  );
};

export default About;





