import React from 'react';
import { useLocation } from 'react-router-dom';
import '../component/home.css';
import phone from '../assets/images/phone.png';
import msg from '../assets/images/msg.png';
import what from '../assets/images/whatsapp.png';
import tele from '../assets/images/telegram.png'; 
// import video from '../assets/images/VideoFinal.mov';

const Home = () => {
  const location = useLocation();
const video='https://siimatechnologies.com/aryavartalifespacesVideo/VideoFinal.mov'
  const headings = {
    "/": { title: 'Live life the Aryavarta One Style', subtitle: '2, 3 BHK Apartment' },
    "/Aryavata_One": { title: 'Live life the Aryavarta One', subtitle: '2, 3 BHK Apartment' },
    // Add more paths and corresponding headings as needed
  };

  const currentHeading = headings[location.pathname] || headings["/"];

  return (
    <> 
      <video autoPlay muted loop id="myVideo">
        <source src={video} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="home-page mb-2" id='home'>
        <div className="icon-bar">
          <a href="tel:9603055170," target="_blank" rel="noopener noreferrer">
            <img src={phone} className='icon' alt="Phone"/>
          </a>
          <a href="mailto:padmavathibuildings@gmail.com" target="_blank" rel="noopener noreferrer">
            <img src={msg} className='icon' alt="Email"/>
          </a>
          <a href="https://wa.me/9603055170," target="_blank" rel="noopener noreferrer">
            <img src={what} className='icon' alt="WhatsApp"/>
          </a>
          <a href="https://t.me/username" target="_blank" rel="noopener noreferrer">
            <img src={tele} className='icon' alt="Telegram"/>
          </a>
        </div>
        <div className="overlay">
          <h1>{currentHeading.title}</h1>
          <p>{currentHeading.subtitle}</p>
          <button className="contact-button active"><a href="#contact">Contact us</a></button>
        </div>
      </div>
    </>
  );
};

export default Home;




