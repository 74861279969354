import React, { useEffect, useState } from 'react';
import './NavIndicator.css';

const NavIndicator = () => {
    const [activeSection, setActiveSection] = useState('home');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            let current = '';

            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;

                if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
                    current = section.getAttribute('id');
                }
            });

            setActiveSection(current);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="nav-indicator">
            <div
                id="home-indicator"
                className={activeSection === 'home' ? 'active' : ''}
                onClick={() => scrollToSection('home')}
                title="Overview" 
            ></div>
            <div
                id="about-indicator"
                className={activeSection === 'about' ? 'active' : ''}
                onClick={() => scrollToSection('about')}
                title="About Us" // Add title attribute for tooltip
            ></div>
            <div
                id="projects-indicator"
                className={activeSection === 'ongoing-projects' ? 'active' : ''}
                onClick={() => scrollToSection('ongoing-projects')}
                title=" Projects" // Add title attribute for tooltip
            ></div>
            <div
                id="reviews-indicator"
                className={activeSection === 'customer-reviews' ? 'active' : ''}
                onClick={() => scrollToSection('customer-reviews')}
                title=" Reviews" // Add title attribute for tooltip
            ></div>
            <div
                id="video-indicator"
                className={activeSection === 'video' ? 'active' : ''}
                onClick={() => scrollToSection('video')}
                title="Video" // Add title attribute for tooltip
            ></div>
            <div
                id="award-indicator"
                className={activeSection === 'award' ? 'active' : ''}
                onClick={() => scrollToSection('award')}
                title="Award" // Add title attribute for tooltip
            ></div>
            <div
                id="contact-indicator"
                className={activeSection === 'contact' ? 'active' : ''}
                onClick={() => scrollToSection('contact')}
                title="Contact" // Add title attribute for tooltip
            ></div>
        </div>
    );
};

export default NavIndicator;

