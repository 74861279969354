import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../component/about.css';
import boader from '../assets/images/boader.png';
import AwardImage from '../assets/images/award1.png'; 

const Award = () => {
  return (
    <div className="award-container" id='awards'>
      <div className="award-header row">
        {/* <div className="getin text-center"> */}
          <h2 className="sticky">Awards<br></br>
          <img src={boader} alt='boader' className='boader'/></h2>
        {/* </div> */}
        {/* <div className="col-md-0.5 d-flex "></div> */}
        <div className="col-md-1 d-flex justify-content-center"></div>
        <div className="col-md-10 d-flex justify-content-center">
          <img src={AwardImage} alt="Awards" className="img-fluid" />
        </div>
        <div className="col-md-1 d-flex justify-content-center"></div>
      </div>
      <div className="text-center mt-3">
        <h4>Winner of the International Excellence Award for Most Admired Ongoing Residential Project in Hyderabad</h4>
      </div>
    </div>
  );
};

export default Award;





