import React from "react";
import Header from "./header";
import Home from "./home";
import About from "./about";
import Amenities from "./amenities";
import ProjectDetails from "./project";
import Masterplan from "./plan";
import Gallery from "./gallary";
import Arya from "./arya";
import Video from "./video";
import Contact from "./contact";





const Index2 = () => {
    return (
        <div>
            <Header/>
            <Home/>
            <About/>
          <Amenities/>
          <ProjectDetails/>
          <Masterplan/>
          <Gallery/>
          <Arya/>
          <Video/>
          <Contact />
        

        </div>
    );
};

export default Index2;