import React from 'react';
import img from '../assets/images/arya1.png';
import '../component/project.css'
import boader from '../assets/images/boader.png'

const Arya = () => {
  return (
    <div className="project-container " id='arya'>
          
      <div className="project-header row">
      {/* <div className='getin text-center'> */}
        <h2 className='sticky'>Building the homes with love <br>
        </br><img src={boader} alt='boader'className='boader'/></h2>
        {/* </div> */}
      
      <div className="project-header">
        <div className=" col-md-6 d-flex justify-content-end align-items-end">
       
        
          <img src={img} alt="project" />
          
        </div>
        <div className="project-detail col-md-6 text-start">
            
        
           
            
            <p>No housing project can be termed complete without amenities that  cater to the needs of every resident from a variety of vantage points.  ARYAVARTA ONE gifts you essential and luxurious amenities that  make your life nothing short of a celebration.

</p>
<p>While multipurpose hall caters to your social gatherings and special  occasions in life, jogging/walking tracks, gymnasium, indoor games,  yoga/dance studio and swimming pool helps you stay fit and  healthy for the rest of your life

</p>
<p>Make sure to experience the zen mode in our lawn and terrace  garden filled to the brim with exotic plants and prove your oratory  skills in the spacious amphitheater. A well-laid children’s play area  uplifts the spirit of your little champions.</p>
        </div>
        </div>
      </div>
      </div>
      
  );
};

export default Arya;



