import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../component/review.css';
import boader from '../assets/images/boader.png';

const reviews = [
    { name: 'Siva Kumar', rating: 5, text: 'Since last one year I have seen very good progress in construction.' },
    { name: 'Raj', rating: 4, text: 'I loved the project' },
    { name: 'Mahesh', rating: 5, text: 'I loved the project and their commitment to the work! I bought a 2 BHK in this project, ' },
    { name: 'Siva Kumar', rating: 5, text: 'I loved the project and their commitment' },
    { name: 'Siva Kumar', rating: 5, text: 'I loved the project and their commitment to the work! I bought a 2 BHK in this project, waiting to see the finished product. Since last one year I have seen very good progress in construction. I hope you deliver our dream home as expected and beyond.' },
];

const CustomerReviews = () => {
    const [showAll, setShowAll] = useState(false);

    const displayedReviews = showAll ? reviews : reviews.slice(0, 3);

    const handleToggleView = () => {
        setShowAll(!showAll);
    };

    return (
        <div className="container-fluid review" id='review'>
            <div className="award-header row">
            {/* <div className="text-center getin pt-4"> */}
            <h2 className="sticky">Customer Reviews<br></br>
            <img src={boader} alt='boader' className='boader'/></h2>
            {/* </div> */}
          
            <div className="row justify-content-center">
                <div className="col-10">
                <div className="text-end mt-1">
                <button className="btn " onClick={handleToggleView}>
                    {showAll ? 'View Less' : 'View More'}
                </button>
            </div>
                    <div className="row">
                        {displayedReviews.map((review, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="card cardlength my-3">
                                    <div className="card-body">
                                        <h5 className="card-title">{review.name}</h5>
                                        <div className="card-rating mb-2">
                                            {Array.from({ length: review.rating }, (_, i) => (
                                                <span key={i} className="text-warning">★</span>
                                            ))}
                                            {Array.from({ length: 5 - review.rating }, (_, i) => (
                                                <span key={i} className="text-muted">★</span>
                                            ))}
                                        </div>
                                        <p className="card-text">{review.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default CustomerReviews;

