import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OngoingProjects from './component/ongoing';
import Index2 from './component/Aryavata_One';// Ensure this component exists
 import Index from '../src/component/index';



const App = () => {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Index />} /> Assuming you have a Home component
        <Route path="/ongoing" element={<OngoingProjects />} />
        <Route path="/Aryavata_One" element={<Index2 />} />
      </Routes>
    </Router>
   
  );
};

export default App;
