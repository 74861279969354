import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Offcanvas } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../component/header.css';
import headerImage from '../assets/images/logo.png'; // Update the path accordingly
import toggle from '../assets/images/toggle.png';

import Offcanvas from 'react-bootstrap/Offcanvas'; 



const Header = () => {
  const [show, setShow] = useState(false);
  const location = useLocation(); // Get the current location

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogoClick = () => {
    window.location.href = '/'; // Adjust the URL if needed
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };
 
  


  return (
    <>
      <div className="header-container">
        <div className="header-content d-flex justify-content-between align-items-end">
          <div className="logo-section">
            <Link to="/" onClick={handleLogoClick}>
              <img src={headerImage} alt="Aryavarta One" className="img-fluid" />
            </Link>
          </div>
          <div className="nav-section d-flex align-items-end last">
            <button className="btn enquire-now-btn">
              <a href="#contact" style={{ color: '#ECBF5D' }}>Enquire Now</a>
            </button>
            <button   className="btn menu-btn" onClick={handleShow}>
              {/* <span className="navbar-toggler-icon"> */}
                <img src={toggle} alt="toggle" className="toggle text-center" />
              {/* </span> */}
            </button>
          </div>
        </div>
      </div>

 

      <Offcanvas show={show} onHide={handleClose} placement="end" className="offcanvas-custom">
        <Offcanvas.Header closeButton style={{ borderBottom: "none" }} />
        <Offcanvas.Body>
          <h6>
            <a href="#home" onClick={handleClose} className={getActiveClass('/')}>Overview</a>
          </h6><br />

          <h6>
            <a href="#about" onClick={handleClose} className={getActiveClass('/about')}>About Us</a>
          </h6><br />

          <h6>
            <a href="/Aryavata_One" onClick={handleClose} className={getActiveClass('/Aryavata_One')}>Aryavarta One</a>
          </h6><br />

          <h6>
            <a href="#contact" onClick={handleClose} className={getActiveClass('/contact')}>Get In Touch</a>
          </h6>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;






